import React, { useState, useEffect } from "react";

import logo from "./logo.svg";
import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";

import HomePage from "./page/HomePage";
import DevPage from "./page/DevPage";

import { useFireStore } from "./firebase/useFireEvent";
import VideoPage from "./page/VideoPage";
import EventPage from "./page/EventPage";
import EventPageParkway from "./page/EventPageParkway";
import EventPageD2M from "./page/EventPageD2M";
import EventPageWDD from "./page/EventPageWDD";

function App() {
  useFireStore();

  const [devMode, setdevMode] = useState(true);
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
      setdevMode(true);
    } else {
      setdevMode(false);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/dev"
          element={devMode ? <DevPage /> : <Navigate to="/" />}
        />

        <Route exact path="/" element={<HomePage />} />
        <Route path="/video" element={<VideoPage />} />
        <Route path="/events/wdd" element={<EventPageWDD />} />
        <Route path="/events/d2m" element={<EventPageD2M />} />
        <Route path="/events/parkway" element={<EventPageParkway />} />
        <Route path="/events/" element={<EventPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
