import { useEffect, useState } from "react";
import { QuizContainer } from "../page/useWindowSize";

export default function NavbarContainer(second) {
  const [isHide, setIsHide] = useState(false);
  useEffect(() => {
    function onScroll(e) {
      const offset = window.scrollY;
      setIsHide(offset > 30 ? true : false);
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <div
      className={
        isHide
          ? "fys-header fys-header--shadow fys-header--collapsed"
          : "fys-header shadow-md"
      }
    >
      <div className="fys-header__wrapper fys-content-wrapper fys-content-wrapper--no-padding-v">
        <a
          className="fys-header__skip-to-main fys-button fys-button--primary fys-a11y-visually-hidden fys-a11y-focusable"
          href="#main-content"
        >
          Skip to main content
        </a>
        <a
          className="fys-header__home-link"
          href="https://foryoursweetheart.sg/"
        >
          <span className="fys-a11y-visually-hidden">For your sweetheart </span>
          <svg className="fys-header__logo" aria-hidden="true">
            <use xlinkHref="#fys-logo"></use>
          </svg>
        </a>
        <nav className="fys-header__nav" aria-label="Main navigation">
          <ul className="fys-header__list">
            <li className="fys-header__list-item">
              <a href="/video" className="fys-header__nav-link">
                video
              </a>
            </li>
            <li className="fys-header__list-item">
              <a
                href="https://connect4yourhealth.com.sg/hf?cid=FysSGTraffic"
                className="fys-header__nav-link"
              >
                About heart failure
              </a>
            </li>
            <li className="fys-header__list-item">
              <a
                href="https://foryoursweetheart.sg/partners"
                className="fys-header__nav-link"
              >
                Partners
              </a>
            </li>
            <li className="fys-header__list-item">
              <a
                href="https://foryoursweetheart.sg/what-can-you-do"
                className="fys-header__nav-link"
              >
                What can you do?
              </a>
            </li>
            <li className="fys-header__list-item">
              <a href="/events" className="fys-header__nav-link">
                Events
              </a>
            </li>
            <li className="fys-header__list-item">
              <a
                href="https://foryoursweetheart.sg/stories-articles"
                className="fys-header__nav-link"
              >
                Stories &amp; articles
              </a>
            </li>
          </ul>
        </nav>
        <input
          type="checkbox"
          id="drawer-toggle"
          className="fys-header__drawer-toggle-input fys-drawer-focusable-items"
          role="button"
          aria-labelledby="drawer-toggle-label"
          aria-pressed="false"
        />
        <label
          htmlFor="drawer-toggle"
          id="drawer-toggle-label"
          className="fys-header__drawer-toggle-label"
          aria-label="Open the navigation menu"
          data-label-open="Open the navigation menu"
          data-label-close="Close the navigation menu"
        >
          <span
            className="fys-header__drawer-toggle-bar"
            aria-hidden="true"
          ></span>
          <span
            className="fys-header__drawer-toggle-bar"
            aria-hidden="true"
          ></span>
          <span
            className="fys-header__drawer-toggle-bar"
            aria-hidden="true"
          ></span>
        </label>
        <div className="fys-header__backdrop" role="presentation"></div>
        <aside
          className="fys-drawer fys-content-wrapper fys-content-wrapper--no-padding-v"
          tabIndex="-1"
          aria-labelledby="drawer-nav"
          aria-hidden="true"
        >
          <nav
            className="fys-drawer__nav"
            id="drawer-nav"
            aria-label="Main navigation"
          >
            <ul className="fys-drawer__list">
              <li className="fys-header__list-item">
                <a href="/video" className="fys-header__nav-link">
                  video
                </a>
              </li>
              <li className="fys-drawer__list-item">
                <a
                  className="fys-drawer__nav-link fys-drawer-focusable-items"
                  href="https://foryoursweetheart.sg/about-the-campaign"
                  tabIndex="-1"
                >
                  About the campaign
                </a>
              </li>
              <li className="fys-drawer__list-item">
                <a
                  className="fys-drawer__nav-link fys-drawer-focusable-items"
                  href="https://foryoursweetheart.sg/partners"
                  tabIndex="-1"
                >
                  Partners
                </a>
              </li>
              <li className="fys-drawer__list-item">
                <a
                  href="https://foryoursweetheart.sg/what-can-you-do"
                  className="fys-drawer__nav-link fys-drawer-focusable-items"
                  tabIndex="-1"
                >
                  What can you do?
                </a>
              </li>
              <li className="fys-drawer__list-item">
                <a
                  className="fys-drawer__nav-link fys-drawer-focusable-items"
                  href="/events"
                  tabIndex="-1"
                >
                  Events
                </a>
              </li>
              <li className="fys-drawer__list-item">
                <a
                  className="fys-drawer__nav-link fys-drawer-focusable-items"
                  href="https://foryoursweetheart.sg/stories-articles"
                  tabIndex="-1"
                >
                  Stories &amp; articles
                </a>
              </li>
            </ul>
          </nav>
        </aside>
      </div>
    </div>
  );
}
