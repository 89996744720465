import NavbarContainer from "../components/NavbarContainer";
import FooterContainer from "../components/FooterContainer";
import Banner_1 from "../asset/banner_event.webp";
import Banner_2 from "../asset/logo.png";
import ShentonBanner from "../asset/banner-1.jpg";
import D2MBanner from "../asset/banner_event.webp";
import WDD2023 from "../asset/event_WDD2023.png";
import { QuizContainer } from "./useWindowSize";

import D2M2024 from "../asset/d2m-2024.webp";
import D2MLogo from "../asset/d2m-logo.webp";
export default function EventPage() {
  return (
    <div className="min-h-full flex flex-col">
      <NavbarContainer />
      <QuizContainer />

      <main className="flex-1 flex flex-col">
        <div
          style={{ maxWidth: "1200px" }}
          className="mx-auto w-full pb-32 pt-32 flex flex-col text-sm text-left space-y-6"
        >
          <div className=" relative ">
            <div className=" relative h-72 md:h-96  ">
              <img
                src={D2M2024}
                alt="banner"
                className=" w-full h-full object-cover  object-top"
              />
              <div
                className="absolute top-1/2 -translate-y-1/2 transform text-white left-5 md:left-10 text-5xl md:text-6xl font-black"
                style={{
                  textShadow: "rgba(0, 0, 0, 0.4) 0px 4px 5px",
                }}
              >
                <div className="flex">
                  D2M{" "}
                  <span className="ml-3 inline-flex justify-center flex-col text-xl md:text-2xl !leading-[1]">
                    Designed <br />
                    to Move
                  </span>
                </div>
                <div className="">CHARITY</div>
                <div className="">RUN</div>
                <div
                  className=" font-black"
                  style={{
                    color: "#ff0000",
                  }}
                >
                  2024
                </div>
              </div>
            </div>

            <div className="bg-white flex flex-col items-center py-8">
              <div
                className=" text-3xl font-semibold"
                style={{
                  color: "#ff0000",
                }}
              >
                14 JULY 2024
              </div>
              <div
                className=" text-xl font-semibold mt-2"
                style={{
                  color: "#ff0000",
                }}
              >
                FLAG OFF AT 8:00 AM{" "}
              </div>
              <div className=" text-black font-semibold text-lg">
                Venue: OCBC Square
              </div>
              <div className=" text-black font-semibold text-lg">
                1 Stadium Place, Singapore 397628
              </div>
            </div>

            <div
              className="bg-blue text-white px-5 md:px-12 py-12"
              style={{ backgroundColor: "#0f1291" }}
            >
              <h2 className=" text-4xl font-semibold ">
                <span
                  className=""
                  style={{
                    color: "#f1562a",
                  }}
                >
                  About
                </span>
                <br />
                The D2M Charity RUN
              </h2>

              <p className="mt-5">
                In a world where diabetes is rapidly becoming the fastest
                growing chronic disease, it is essential to acknowledge the
                pressing need for change. Here in Singapore, the statistics are
                alarming: One in three citizens faces a lifetime risk of
                developing diabetes, and shockingly, 50.4% of individuals in
                Singapore living with diabetes went undiagnosed as of 2021
                according to the International Diabetes Federation (IDF). As we
                project the potential of having one million people with diabetes
                by 2050, it is evident that we need to take action to alter this
                course.
              </p>

              <p className="mt-5">
                The D2M (Designed to Move) Charity Run emerges as a powerful
                symbol of hope and unity, signifying our collective
                determination to make a difference. It represents a profound
                belief in our capacity to pave a path towards better health
                management and lifestyle habits for all Singaporeans. With our
                inaugural run successfully held in 2021, we embark on our third
                annual run with even greater resolve and purpose.
              </p>

              <p className="mt-5">
                At the heart of the D2M Charity Run lies Diabetes 2 Mellitus,
                the most prevalent form of diabetes. This condition not only
                brings about cardiovascular diseases but also gives rise to
                complications that can significantly impact various bodily
                functions. The question arises: As we live longer, are we truly
                living a life of quality and vitality? The answer lies in the
                choices we make daily.
              </p>

              <p className="mt-5">
                Emphasising the pivotal role of a healthy diet and regular
                exercise, we affirm that our bodies are indeed designed to move.
                Whether you are an athletic runner, a jogging enthusiast, or a
                casual walker, this event welcomes everyone to participate and
                contribute to a worthy cause. Each step you take is not merely a
                physical endeavour; it becomes a testament to your commitment to
                embrace a healthier lifestyle while supporting a charitable
                initiative.
              </p>

              <p className="mt-5">
                By joining the D2M Charity Run, you become a catalyst for
                change, a beacon of hope in the face of a growing health crisis.
                Your participation transcends the confines of a charity run; it
                embodies a philosophical journey of self-awareness and communal
                responsibility. Together, we can alter the trajectory of
                diabetes in Singapore, fostering a future where health and
                happiness intertwine.
              </p>

              <p className="mt-5">
                Let your footsteps echo with purpose, your strides resonate with
                meaning, and your presence ignite the spark of transformation.
                Join us in this significant event, as we propel ourselves
                towards a brighter, healthier, and more fulfilling tomorrow. The
                time is now, and power is in unity. Together, we will redefine
                what it means to live a life of vitality - a life that truly
                moves with purpose.
              </p>
            </div>

            <div className=" py-10 px-2">
              <img
                src={D2MLogo}
                alt="banner logo"
                className=" w-full max-w-xl h-full object-cover  object-top mx-auto"
                style={{
                  width: "100%",
                }}
              />
            </div>

            <div className="py-10 flex items-center justify-center  text-gray-600">
              <a
                href="https://www.d2m.org.sg/d2mrun-registration"
                target="_blank"
                rel="noreferrer"
                className="self-center "
              >
                <button
                  className=" text-white font-bold h-12 px-8 rounded text-base"
                  style={{
                    backgroundColor: "#e9292f",
                  }}
                >
                  Register Now
                </button>
              </a>
            </div>
          </div>

          {/* PAST EVENT */}
          <section className=" pt-24 px-5">
            <div className="flex flex-col space-y-8 mb-5  text-gray-600 text-xl">
              Past Event
            </div>

            <div className="grid grid-cols-1  md:grid-cols-3 items-center md:items-stretch md:flex-row flex-wrap  gap-6">
              <a
                href="/events/wdd"
                target="_blank"
                rel="noreferrer"
                className="self-center contents "
              >
                <div className="flex flex-col shadow w-full">
                  <div className="border relative pt-[70%]">
                    <img
                      src={WDD2023}
                      alt=""
                      className=" absolute inset-0 w-full h-full object-cover object-center"
                    />
                  </div>

                  <div className="px-5 leading-[1.2] py-6 text-lg text-gray-600 flex-none flex items-center">
                    World Diabetes Day 2023
                  </div>
                </div>
              </a>

              <a
                href="/events/parkway"
                target="_blank"
                rel="noreferrer"
                className="self-center contents "
              >
                <div className="flex flex-col shadow  w-full">
                  <img
                    src={ShentonBanner}
                    alt=""
                    className="border flex-1 object-cover"
                  />

                  <div className="px-5 leading-[1.2] py-6 text-lg text-gray-600 flex-none flex items-center">
                    Parkway Shenton 50th Anniversary Celebrations Carnival
                  </div>
                </div>
              </a>

              <a
                href="/events/d2m"
                target="_blank"
                rel="noreferrer"
                className="self-center contents  "
              >
                <div className="flex flex-col shadow  w-full">
                  <img
                    src={D2MBanner}
                    alt=""
                    className="border flex-1 object-cover"
                  />

                  <div className="px-5 leading-[1.2] py-6 text-lg text-gray-600 flex-none flex items-center">
                    The D2M Charity RUN
                  </div>
                </div>
              </a>
            </div>
          </section>
        </div>
      </main>
      <FooterContainer />
    </div>
  );
}
