import React, { useState, useEffect, useRef } from "react";

import { Dev_AddClinic } from "../firebase/useFireEvent";
import { useClinicData } from "../firebase/useFireEvent";

export default function DevPage() {
  const db_clinic = useClinicData();
  // console.log(db_clinic);
  const inputref = useRef();
  const [data, setdata] = useState({});

  function oc_input(e) {
    const input = e.target.value;
    const [header, ...rows] = input.split("\n").map((x) => {
      return x.trim().split("\t");
    });

    setdata({
      header,
      content: rows,
    });
  }

  function click_add_clinic() {
    console.log("add clinic");
    // log data
    data.content.map((x) => {
      const dataObj = {};
      data.header.map((head, i) => {
        dataObj[head] = x[i];
      });

      Dev_AddClinic(dataObj, () => {
        console.log("create clinic success");
      });
    });
  }
  if (db_clinic === null) return <div>loading</div>;

  if (db_clinic.length === 0) return <div>loading</div>;

  return (
    <div className="p-5">
      <div className="flex justify-between">
        <p className="text-black text-xl">Dev Page</p>
        <div
          onClick={click_add_clinic}
          className="cursor-pointer bg-blue-600 rounded-md text-white font-bold flex items-center justify-center px-5 py-2"
        >
          Create
        </div>
      </div>
      <textarea
        ref={inputref}
        onChange={oc_input}
        className="border p-2 w-full mt-5 text-xs"
      ></textarea>
      <div className="border mt-5 overflow-x-auto min-x-0">
        {data.header && (
          <table className="table-auto text-xs">
            <thead>
              <tr>
                {data.header.map((x) => {
                  return (
                    <th key={x} className="px-3">
                      {x}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.content.map((x) => {
                return (
                  <tr>
                    {x.map((x) => {
                      return (
                        <td
                          className="px-3 whitespace-nowrap"
                          style={{
                            maxWidth: "400px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {x}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      {/* KLINIK DATA */}
      <div className=" overflow-x-auto">
        {
          <table className="table-auto text-xs text-left">
            <thead>
              <tr>
                {Object.keys(db_clinic[0]).map((x) => {
                  return (
                    <th key={x} className="px-3">
                      {x}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {db_clinic.map((x) => {
                const key = Object.keys(db_clinic[0]);
                return (
                  <tr>
                    {key.map((y) => {
                      return (
                        <td
                          key={y}
                          className="px-3 whitespace-nowrap"
                          style={
                            {
                              // maxWidth: "400px",
                              // textOverflow: "ellipsis",
                              // overflow: "hidden",
                            }
                          }
                        >
                          {x[y]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      </div>
    </div>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
