import { initializeApp, getApps } from "@firebase/app";
import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";
import { getAnalytics } from "firebase/analytics";
const useFirebaseAppImpl = () => {
  const [app, setApp] = useState(null);

  const firebaseConfig = {
    apiKey: "AIzaSyB5LgL1yS2ApjFMznnU4b_VTua6dJVTPaM",
    authDomain: "fys-freescreening.firebaseapp.com",
    projectId: "fys-freescreening",
    storageBucket: "fys-freescreening.appspot.com",
    messagingSenderId: "8935621575",
    appId: "1:8935621575:web:72a0dfc8a64f4dceb0cfe2",
    measurementId: "G-CVCQS7G2YC",
  };

  useEffect(() => {
    if (getApps().length > 0) return;
    console.log("FIREBASE @ initializeApp");
    setApp(initializeApp(firebaseConfig));
    const analytics = getAnalytics();
  }, []);
  return app;
};
export const useFirebaseApp = singletonHook(null, useFirebaseAppImpl);
