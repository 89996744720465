import React, { useState, useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import icon_quiz from "../asset/quiz.png";

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
const QUESTION = [
  {
    title: (
      <div>
        Which of these is a risk factor<sup>1</sup> for type 2 diabetes?
      </div>
    ),

    choices: [
      "Overweight",
      "Sedentary lifestyle",
      "High cholesterol",
      "Age (35 years old and above)",
      "All of the above",
    ],
    correct_answer: 4,
    footnote: (
      <div className=" text-xs text-white/70 italic">
        <div className=" text-white/90 font-semibold">References</div>
        <sup>1</sup> Mayo Clinic. Type 2 diabetes. Last accessed 9 April 2024.
      </div>
    ),
  },
  {
    title: (
      <div>
        Is type 2 diabetes preventable<sup>2</sup>?
      </div>
    ),
    choices: ["Yes", "No"],
    correct_answer: 0,
    footnote: (
      <div className=" text-sm">
        You can prevent or delay type 2 diabetes, by making lifestyle
        changes—more physical activities, adopt healthier eating habits, quit
        smoking etc.
        <div className="mt-3 text-xs text-white/70 italic">
          <div className=" text-white/90 font-semibold">References</div>
          <sup>2</sup> Centers for Disease Control and Prevention. Prevent Type
          2 Diabetes. Last accessed 9 April 2024.
        </div>
      </div>
    ),
  },
  {
    title: (
      <div>
        Diabetes may cause heart and kidney complications<sup>3</sup>?
      </div>
    ),

    choices: ["Yes", "No"],
    correct_answer: 0,
    footnote: (
      <div className=" text-sm">
        Over time, high blood sugar can cause blood vessels to get narrower and
        clogged. As the kidneys receive less blood, less waste and fluid are
        excreted out of the body. Kidney disease that is caused by diabetes is
        called “diabetic kidney disease.”
        <div className="mt-3 text-xs text-white/70 italic">
          <div className=" text-white/90 font-semibold">References</div>
          <sup>3</sup> HealthXchange. Diabetes Complications & Management. Last
          accessed 9 April 2024.
        </div>
      </div>
    ),
  },
  {
    title: (
      <div>
        Do diabetics require special diets or meals<sup>4</sup>?
      </div>
    ),

    choices: ["Yes", "No"],
    correct_answer: 1,

    footnote: (
      <div className=" text-sm">
        Whether you're trying to prevent or control diabetes, your nutritional
        needs are the same as everyone else, so no special foods are necessary.
        The top consideration is to beware of one’s carbohydrate intake in terms
        of quantity and portion.
        <div className="mt-3 text-xs text-white/70 italic">
          <div className=" text-white/90 font-semibold">References</div>
          <sup>4</sup> HealthXchange. Diabetes: What To Eat. Last accessed 9
          April 2024.
        </div>
      </div>
    ),
  },
  {
    title: (
      <div>
        Early detection/diagnosis of diabetes is important<sup>5</sup>:
      </div>
    ),

    choices: [
      "To prevent serious diabetes related complications.",
      "To prevent permanent damage to organs (eyes, heart and kidney).",
      "To better control the diabetes symptoms and blood glucose.",
      "All of the above",
    ],
    correct_answer: 3,

    footnote: (
      <div className=" text-sm">
        <div className="mt-3 text-xs text-white/70 italic">
          <div className=" text-white/90 font-semibold">References</div>
          <sup>5</sup> Channel News Asia. Diabetes screenings to play a big role
          in Singapore, with 1 in 3 people at risk of the disease. Last accessed
          9 April 2024.
        </div>
      </div>
    ),
  },
];
export function QuizContainer(params) {
  const [is_show, set_is_show] = useState(false);

  const [current_question, set_current_question] = useState(0);
  const [selected_answer, set_selected_answer] = useState(-1);

  const [is_submitted, set_is_submitted] = useState(false);

  // noscroll when show
  useEffect(() => {
    if (is_show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [is_show]);

  const handle_submit = () => {
    if (is_submitted) {
      if (current_question === QUESTION.length - 1) {
        set_is_show(false);

        // redirect to /
        window.location.href = "/";
        return;
      }
      set_is_submitted(false);
      set_current_question(current_question + 1);
      set_selected_answer(-1);
    } else {
      set_is_submitted(true);
      const analytics = getAnalytics();
      console.log("@GA quiz", {
        question: current_question + 1,
        answer: `q${current_question + 1}_${selected_answer + 1}`,
      });
      logEvent(analytics, "quiz", {
        question: current_question + 1,
        answer: `q${current_question + 1}_${selected_answer + 1}`,
      });
    }
  };

  const is_correct =
    selected_answer === QUESTION[current_question].correct_answer;

  const correct_answer = QUESTION[current_question].correct_answer;

  if (!is_show)
    return (
      <button
        onClick={() => {
          set_is_show(true);
          set_current_question(0);
          set_selected_answer(-1);
          set_is_submitted(false);
        }}
        className="fixed z-40 bottom-2 right-2 w-28 h-28 md:w-20 md:h-20 animate-bounce"
      >
        <img src={icon_quiz} alt="" className=" w-full h-full object-contain" />
      </button>
    );

  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center bg-black bg-opacity-50">
      <div
        className=" w-full h-full flex flex-col items-center overflow-y-auto min-h-0  px-2 "
        style={{
          paddingTop: "5vh",
          paddingBottom: "5vh",
        }}
      >
        <div className="flex-1"></div>

        <div className=" bg-red-ci rounded-xl text-white w-full max-w-sm p-6 ">
          {QUESTION[current_question] && (
            <div>
              <p className="font-semibold">
                {QUESTION[current_question].title}
              </p>
              <div className="mt-4 space-y-2">
                {QUESTION[current_question].choices.map((item, index) => {
                  const is_sel = selected_answer === index;

                  return (
                    <div
                      key={index}
                      className={`flex items-center cursor-pointer border rounded-md px-3 py-2   ${
                        !is_submitted &&
                        (is_sel
                          ? "bg-white bg-opacity-100 text-black text-opacity-80 transition"
                          : "hover:bg-white hover:bg-opacity-10")
                      }
                      
                      ${
                        is_submitted &&
                        is_sel &&
                        (is_correct
                          ? "bg-green-300 bg-opacity-100 text-black text-opacity-80 transition font-bold"
                          : "bg-white bg-opacity-100 text-black text-opacity-80 transition")
                      }

                      ${
                        is_submitted &&
                        !is_sel &&
                        index === correct_answer &&
                        "bg-yellow-400 bg-opacity-100 text-black text-opacity-80 transition font-bold"
                      }
                      `}
                      style={{
                        minHeight: "2.5rem",
                      }}
                      onClick={() => {
                        if (is_submitted) return;
                        set_selected_answer(index);
                      }}
                    >
                      <p className=" leading-5 text-sm">{item}</p>
                    </div>
                  );
                })}
              </div>

              {is_submitted && (
                <p className="mt-4 text-sm text-opacity-80 text-white">
                  {QUESTION[current_question].footnote}
                </p>
              )}

              {/* btn submit / next */}
              <button
                disabled={selected_answer === -1}
                className={`mt-4 w-full flex items-center justify-center cursor-pointer border rounded-md px-3 py-2 bg-white text-black text-opacity-70  ${
                  selected_answer === -1
                    ? "opacity-50 "
                    : "hover:bg-white transition"
                }`}
                style={{
                  minHeight: "2.5rem",
                }}
                onClick={handle_submit}
              >
                <p className=" leading-5 text-sm capitalize font-semibold">
                  {is_submitted
                    ? // last question
                      current_question === QUESTION.length - 1
                      ? "Get your FREE HbA1c diabetes screening voucher now!"
                      : "next"
                    : "submit"}
                </p>
              </button>
            </div>
          )}
        </div>

        <div className="flex-1"></div>
      </div>
    </div>
  );
}
