import NavbarContainer from "../components/NavbarContainer";
import FooterContainer from "../components/FooterContainer";
import Banner_1 from "../asset/banner_event.webp";
import Banner_2 from "../asset/logo.png";
import ShentonBanner from "../asset/banner-1.jpg";
import { QuizContainer } from "./useWindowSize";

export default function EventPageD2M() {
  return (
    <div className="min-h-full flex flex-col">
      <NavbarContainer />
      <QuizContainer />

      <main className="flex-1 flex flex-col">
        <div
          style={{ maxWidth: "1200px" }}
          className="mx-auto w-full pb-32 pt-32 flex flex-col text-sm text-left space-y-6 px-6"
        >
          <div className=" relative h-96  ">
            <img
              src={Banner_1}
              alt="banner"
              className=" w-full h-full object-cover  object-top"
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 transform text-white left-10 text-6xl font-bold"
              style={{
                textShadow: "rgba(0, 0, 0, 0.4) 0px 4px 5px",
              }}
            >
              <div className="flex">
                D2M{" "}
                <span className="ml-3 inline-flex justify-center flex-col text-2xl leading-5">
                  <span>designed</span>
                  <span>to move</span>
                </span>
              </div>
              <div className="">CHARITY</div>
              <div className="">RUN</div>
              <div
                className=""
                style={{
                  color: "#f1562a",
                }}
              >
                2023
              </div>
            </div>
          </div>

          <div className="bg-white flex flex-col items-center">
            <div
              className=" text-3xl font-semibold"
              style={{
                color: "#f1562a",
              }}
            >
              10 SEPTEMBER 2023
            </div>
            <div
              className=" text-xl font-semibold mt-2"
              style={{
                color: "#f1562a",
              }}
            >
              FLAG OFF AT 8:00 AM{" "}
            </div>
            <div className=" text-black font-semibold text-lg">
              Venue: OCBC Square
            </div>
            <div className=" text-black font-semibold text-lg">
              1 Stadium Place, Singapore 397628
            </div>
          </div>

          <div
            className="bg-blue text-white px-12 py-12"
            style={{ backgroundColor: "#0f1291" }}
          >
            <h2 className=" text-2xl font-semibold ">
              <span
                className=""
                style={{
                  color: "#f1562a",
                }}
              >
                About
              </span>
              <br />
              The D2M Charity RUN
            </h2>

            <p className="mt-5">
              In a world where diabetes is rapidly becoming the fastest growing
              chronic disease, it is essential to acknowledge the pressing need
              for change. Here in Singapore, the statistics are alarming: One in
              three citizens faces a lifetime risk of developing diabetes, and
              shockingly, 50.4% of individuals in Singapore living with diabetes
              went undiagnosed as of 2021 according to the International
              Diabetes Federation (IDF). As we project the potential of having
              one million people with diabetes by 2050, it is evident that we
              need to take action to alter this course.
            </p>

            <p className="mt-5">
              The D2M (Designed to Move) Charity Run emerges as a powerful
              symbol of hope and unity, signifying our collective determination
              to make a difference. It represents a profound belief in our
              capacity to pave a path towards better health management and
              lifestyle habits for all Singaporeans. With our inaugural run
              successfully held in 2021, we embark on our third annual run with
              even greater resolve and purpose.
            </p>

            <p className="mt-5">
              At the heart of the D2M Charity Run lies Diabetes 2 Mellitus, the
              most prevalent form of diabetes. This condition not only brings
              about cardiovascular diseases but also gives rise to complications
              that can significantly impact various bodily functions. The
              question arises: As we live longer, are we truly living a life of
              quality and vitality? The answer lies in the choices we make
              daily.
            </p>

            <p className="mt-5">
              Emphasising the pivotal role of a healthy diet and regular
              exercise, we affirm that our bodies are indeed designed to move.
              Whether you are an athletic runner, a jogging enthusiast, or a
              casual walker, this event welcomes everyone to participate and
              contribute to a worthy cause. Each step you take is not merely a
              physical endeavour; it becomes a testament to your commitment to
              embrace a healthier lifestyle while supporting a charitable
              initiative.
            </p>

            <p className="mt-5">
              By joining the D2M Charity Run, you become a catalyst for change,
              a beacon of hope in the face of a growing health crisis. Your
              participation transcends the confines of a charity run; it
              embodies a philosophical journey of self-awareness and communal
              responsibility. Together, we can alter the trajectory of diabetes
              in Singapore, fostering a future where health and happiness
              intertwine.
            </p>

            <p className="mt-5">
              Let your footsteps echo with purpose, your strides resonate with
              meaning, and your presence ignite the spark of transformation.
              Join us in this significant event, as we propel ourselves towards
              a brighter, healthier, and more fulfilling tomorrow. The time is
              now, and power is in unity. Together, we will redefine what it
              means to live a life of vitality - a life that truly moves with
              purpose.
            </p>
          </div>

          <div className="">
            <img
              src={Banner_2}
              alt="banner logo"
              className=" w-full h-full object-cover  object-top mx-auto"
              style={{
                maxWidth: "700px",
                width: "100%",
              }}
            />
          </div>

          <div className="py-10 flex items-center justify-center  text-gray-600">
            <a
              href="https://www.d2m.org.sg/d2mrun-registration"
              target="_blank"
              rel="noreferrer"
              className="self-center "
            >
              <button
                className=" text-white font-bold h-12 px-8 rounded text-base"
                style={{
                  backgroundColor: "#e9292f",
                }}
              >
                Register Now
              </button>
            </a>
          </div>
        </div>
      </main>
      <FooterContainer />
    </div>
  );
}
