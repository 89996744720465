import NavbarContainer from "../components/NavbarContainer";
import FooterContainer from "../components/FooterContainer";
import Banner_1 from "../asset/banner_event.webp";
import Banner_2 from "../asset/logo.png";
import ShentonBanner from "../asset/banner-1.jpg";
import D2MBanner from "../asset/banner_event.webp";
import WDD2023 from "../asset/event_WDD2023.png";
export default function EventPageWDD() {
  return (
    <div className="min-h-full flex flex-col">
      <NavbarContainer />
      <main className="flex-1 flex flex-col">
        <div
          style={{ maxWidth: "1200px" }}
          className="mx-auto w-full pb-32 pt-32 flex flex-col text-sm text-left space-y-6 px-6"
        >
          <div className=" relative ">
            <img
              src={WDD2023}
              alt="banner"
              className=" w-full h-full object-cover  object-top"
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 transform text-white left-10 text-6xl font-bold"
              style={{
                textShadow: "rgba(0, 0, 0, 0.4) 0px 4px 5px",
              }}
            ></div>
          </div>
        </div>
      </main>
      <FooterContainer />
    </div>
  );
}
