import NavbarContainer from "../components/NavbarContainer";
import FooterContainer from "../components/FooterContainer";
import Banner_1 from "../asset/banner-1.jpg";
import Banner_2 from "../asset/banner-2.jpg";
import { QuizContainer } from "./useWindowSize";

export default function EventPageParkway() {
  return (
    <div className="min-h-full flex flex-col">
      <NavbarContainer />
      <QuizContainer />

      <main className="flex-1 flex flex-col">
        <div
          style={{ maxWidth: "1200px" }}
          className="mx-auto w-full pb-32 pt-32 flex flex-col text-sm text-left space-y-6 px-6"
        >
          <div className="self-center">
            <img
              src={Banner_1}
              alt="banner"
              className="object-contain "
              style={{
                width: "700px",
              }}
            />
          </div>
          <div className="flex flex-col space-y-8  text-gray-600">
            <div className="self-center">
              <img
                src={Banner_2}
                alt="banner"
                className="object-contain "
                style={{
                  width: "700px",
                }}
              />
            </div>

            <p>
              Join us for the{" "}
              <strong className="underline">
                Parkway Shenton 50th Anniversary Celebrations Carnival
              </strong>{" "}
              at Buona Vista Community Club Level 2 Multi-purpose Hall, on{" "}
              <strong className="underline">
                May 27, 2023 from 8am to 12pm
              </strong>
              . Click the button below to book your health screening
              slot for the event!
            </p>

            {/* link to https://bit.ly/parkwayshenton50 */}
            <a
              href="https://bit.ly/parkwayshenton50"
              target="_blank"
              rel="noreferrer"
              className="self-center "
            >
              <button
                className=" text-white font-bold h-12 px-8 rounded text-base"
                style={{
                  backgroundColor: "#e9292f",
                }}
              >
                Register Now
              </button>
            </a>
          </div>
        </div>
      </main>
      <FooterContainer />
    </div>
  );
}
