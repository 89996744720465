import { useFirebaseApp } from "./useFirebaseApp";
import { getFirestore } from "firebase/firestore";

import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";

import {
  collection,
  getDoc,
  addDoc,
  getDocs,
  setDoc,
  query,
  where,
  updateDoc,
  writeBatch,
  doc,
  onSnapshot,
  documentId,
  serverTimestamp,
  deleteDoc,
  get,
  runTransaction,
} from "firebase/firestore";
import { findAllInRenderedTree } from "react-dom/test-utils";

var _ = require("lodash");

let app = null;
let db = null;

// ============================================================================
// Init Firestore Service
// ============================================================================

const useFireStoreImpl = () => {
  const [state, setState] = useState("");
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    console.log(`FIREEVENT @ Init`);
    // Admin_CreateUser();
  }, [app]);
  return state;
};

export const useFireStore = singletonHook("", useFireStoreImpl);

// ============================================================================
// Client Only
// ============================================================================

export async function client_check_test(email, callback) {
  const userDoc = await getDoc(doc(db, "user_data", email));
  if (!userDoc.exists()) {
    callback(false);
  } else {
    const data = userDoc.data();
    if (!data.test) {
      const result = await updateDoc(doc(db, "user_data", email), {
        test: serverTimestamp(),
      });
      callback("Your entry has been submitted, thank you for your cooperation");
    } else {
      callback(
        `You have checked in at ${data.test.toDate().toLocaleTimeString()}`
      );
    }
  }
}
export async function client_check_1(email, callback) {
  const userDoc = await getDoc(doc(db, "user_data", email));
  if (!userDoc.exists()) {
    callback(false);
  } else {
    const data = userDoc.data();
    if (!data.checkin1) {
      const result = await updateDoc(doc(db, "user_data", email), {
        checkin1: serverTimestamp(),
      });
      callback("Your entry has been submitted, thank you for your cooperation");
    } else {
      callback(
        `You have checked in at ${data.checkin1.toDate().toLocaleTimeString()}`
      );
    }
  }
}
export async function client_check_2(email, callback) {
  const userDoc = await getDoc(doc(db, "user_data", email));
  if (!userDoc.exists()) {
    callback(false);
  } else {
    const data = userDoc.data();
    if (!data.checkin2) {
      const result = await updateDoc(doc(db, "user_data", email), {
        checkin2: serverTimestamp(),
      });
      callback("Your entry has been submitted, thank you for your cooperation");
    } else {
      callback(
        `You have checked in at ${data.checkin2.toDate().toLocaleTimeString()}`
      );
    }
  }
}

export async function client_check_3(email, callback) {
  const userDoc = await getDoc(doc(db, "user_data", email));
  if (!userDoc.exists()) {
    callback(false);
  } else {
    const data = userDoc.data();
    if (!data.checkin3) {
      const result = await updateDoc(doc(db, "user_data", email), {
        checkin3: serverTimestamp(),
      });
      callback("Your entry has been submitted, thank you for your cooperation");
    } else {
      callback(
        `You have checked in at ${data.checkin3.toDate().toLocaleTimeString()}`
      );
    }
  }
}

// ============================================================================
// Dev Only
// ============================================================================

const useUserDataImpl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "user_data");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const content = [];
      const header = [];
      snap.forEach((x) => {
        if (x.id === "header") header.push(...x.data().data);
        else content.push(x.data());
      });

      // console.log(header);
      setState({ header, content });
    });
  }, [app]);
  return state;
};

export const useUserData = singletonHook(null, useUserDataImpl);

const useLuckdraw1Impl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "luckdraw1");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const data = [];
      snap.forEach((x) => {
        data.push(x.data().data);
      });
      setState(data);
    });
  }, [app]);
  return state;
};
export const useLuckdraw1 = singletonHook(null, useLuckdraw1Impl);

const useLuckdraw2Impl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "luckdraw2");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const data = [];
      snap.forEach((x) => {
        data.push(x.data().data);
      });
      setState(data);
    });
  }, [app]);
  return state;
};
export const useLuckdraw2 = singletonHook(null, useLuckdraw2Impl);

const useLuckdraw3Impl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "luckdraw3");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const data = [];
      snap.forEach((x) => {
        data.push(x.data().data);
      });
      setState(data);
    });
  }, [app]);
  return state;
};
export const useLuckdraw3 = singletonHook(null, useLuckdraw3Impl);

const useLuckdraw4Impl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "luckdraw4");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const data = [];
      snap.forEach((x) => {
        data.push(x.data().data);
      });
      setState(data);
    });
  }, [app]);
  return state;
};
export const useLuckdraw4 = singletonHook(null, useLuckdraw4Impl);

const useLuckdrawAllImpl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "all");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const data = [];
      snap.forEach((x) => {
        data[x.id] = x.data().data;
      });

      console.log(data);
      setState(data);
    });
  }, [app]);
  return state;
};
export const useLuckdrawAll = singletonHook(null, useLuckdrawAllImpl);

export async function Admin_Add_Userdata(input) {
  let header = [];
  let content = [];
  let updateObjectArr = [];
  const row_arr = input.split("\n");
  header = row_arr[0].split("\t");
  for (let i = 1; i < row_arr.length; i++) {
    content.push(row_arr[i]);
  }
  const userDataRef = collection(db, "user_data");
  const batch = writeBatch(db);

  // batch.set(doc(db, "user_data", "header"), { data: header });
  setDoc(doc(db, "user_data", "header"), { data: header });
  content.map((row) => {
    const row_arr = row.split("\t");
    const update_obj = {};
    header.map((head, i) => {
      update_obj[head] = row_arr[i];
    });
    updateObjectArr.push(update_obj);
  });
  // batch.set(doc(db, "user_data", row_arr[0]), update_obj);
  // });
  console.log(updateObjectArr);
  // const res = await batch.commit();
  // console.log(res);

  const batches = _.chunk(updateObjectArr, 500).map((updateObject) => {
    const batch = writeBatch(db);
    updateObject.forEach((row) => {
      batch.set(doc(db, "user_data", row.id), row);
    });
    return batch.commit();
  });

  await Promise.all(batches);
}

export async function ResetLuckdraw1(dataArr) {
  const col = collection(db, "luckdraw1");
  const res = await getDocs(col);
  res.forEach((x) => {
    deleteDoc(x.ref);
  });

  const batch = writeBatch(db);
  dataArr.map((x, i) => {
    batch.set(doc(db, "luckdraw1", i.toString()), { data: x });
  });
  await batch.commit();
  console.log("complted");
}

export async function ResetLuckdraw2(dataArr) {
  const col = collection(db, "luckdraw2");
  const res = await getDocs(col);
  res.forEach((x) => {
    deleteDoc(x.ref);
  });

  const batch = writeBatch(db);
  dataArr.map((x, i) => {
    batch.set(doc(db, "luckdraw2", i.toString()), { data: x });
  });
  await batch.commit();
  console.log("complted");
}

export async function ResetLuckdraw3(dataArr) {
  const col = collection(db, "luckdraw3");
  const res = await getDocs(col);
  res.forEach((x) => {
    deleteDoc(x.ref);
  });

  const batch = writeBatch(db);
  dataArr.map((x, i) => {
    batch.set(doc(db, "luckdraw3", i.toString()), { data: x });
  });
  await batch.commit();
  console.log("complted");
}

export async function ResetLuckdraw4(dataArr) {
  const col = collection(db, "luckdraw4");
  const res = await getDocs(col);
  res.forEach((x) => {
    deleteDoc(x.ref);
  });

  const batch = writeBatch(db);
  dataArr.map((x, i) => {
    batch.set(doc(db, "luckdraw4", i.toString()), { data: x });
  });
  await batch.commit();
  console.log("complted");
}

export async function Admin_AddUser(id, data_obj, callback) {
  const userDataDoc = doc(db, "user_data", id);
  const res = await setDoc(userDataDoc, data_obj);
  callback(true);
}

export async function Admin_DelUser(id, callback) {
  const userDataDoc = doc(db, "user_data", id);
  const res = await deleteDoc(userDataDoc);
  callback(true);
}

// =============== new ================
const useClinicDataImpl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "clinic_data");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const data = [];
      snap.forEach((x) => {
        data.push(x.data());
      });
      setState(data);
    });
  }, [app]);
  return state;
};
export const useClinicData = singletonHook(null, useClinicDataImpl);

export async function Dev_AddClinic(obj, callback) {
  const clinic_ref = doc(db, "clinic_data", obj.name);
  obj.download = 0;
  try {
    const res = await setDoc(clinic_ref, obj);
  } catch (e) {
    console.log(e);
  }
  callback();
}

export async function Client_DownloadVoucher(clinicName) {
  const clinic_ref = doc(db, "clinic_data", clinicName);
  const total_ref = doc(db, "download_data", "download_count");
  try {
    return await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(clinic_ref);
      const totalDoc = await transaction.get(total_ref);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }
      const data = sfDoc.data();

      if (data.download >= data.voucher) {
        // no more dl
        throw "no more voucher!";
      } else {
        const newCount = (totalDoc.data()?.download_count || 0) + 1;
        transaction.update(total_ref, {
          download_count: newCount,
        });
        transaction.update(clinic_ref, { download: (data.download || 0) + 1 });
        return newCount;
      }
    });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
    return false;
  }
}
